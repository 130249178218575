const TikTokNew = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8365 32 32 24.8369 32 16C32 7.16309 24.8365 0 16 0C7.16345 0 0 7.16309 0 16C0 24.8369 7.16345 32 16 32ZM19.4657 14C20.5145 14.751 21.7727 15.1523 23.0625 15.1494V12.5898C22.3645 12.5898 21.6819 12.3848 21.0996 12C20.6903 11.7363 20.3392 11.3916 20.0676 10.9873C19.796 10.583 19.6097 10.1279 19.5203 9.64941C19.4822 9.43555 19.464 9.21777 19.4657 9H16.8876V19.2354C16.8876 19.6963 16.7421 20.1455 16.4717 20.5186C16.2012 20.8916 15.8198 21.1699 15.382 21.3135C14.9441 21.457 14.472 21.458 14.0333 21.3174C13.5946 21.1768 13.2115 20.9004 12.939 20.5293C12.6664 20.1572 12.5181 19.709 12.5154 19.249C12.5127 18.7881 12.6556 18.3379 12.9238 17.9629C13.192 17.5889 13.5718 17.3086 14.0088 17.1621C14.4458 17.0166 14.9178 17.0117 15.3574 17.1504V14.5166C14.3969 14.3838 13.4186 14.5469 12.5532 14.9854C11.688 15.4229 10.9771 16.1143 10.5154 16.9668C10.0538 17.8203 9.86353 18.7939 9.97021 19.7578C10.0768 20.7217 10.475 21.6299 11.1118 22.3613C11.7485 23.0928 12.5935 23.6123 13.5334 23.8506C14.4735 24.0889 15.4639 24.0342 16.3722 23.6943C17.2804 23.3545 18.0634 22.7451 18.6161 21.9492C19.1689 21.1523 19.4653 20.2051 19.4657 19.2354V14Z"
        fill="white"
      />
    </svg>
  )
}

export default TikTokNew
